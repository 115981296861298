import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'

class NotFoundPage extends React.Component {
  componentDidMount() {
    window.setTimeout(() => {
      navigate('/')
    }, 3000)
  }
  render() {
    return (
      <Layout>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>You will be redirected to our Home page</p>
      </Layout>
    )
  }
}

export default NotFoundPage
